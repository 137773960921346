/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/mdui@0.4.3/dist/js/mdui.min.js
 * - /gh/mcstudios/glightbox@3.3.0/dist/js/glightbox.min.js
 * - /npm/aplayer@1.10.1/dist/APlayer.min.js
 * - /npm/js-cookie@2.2.1/src/js.cookie.min.js
 * - /gh/axios/axios@0.19.2/dist/axios.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
